:root {
  --background-color-dark: #191919;
  --background-color: #262626;
  --primary-color: hsl(42, 40%, 50%);
  --secondary-color: hsla(42, 40%, 70%, 50%);
}

body {
  background: var(--background-color-dark);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
