.Card {
  border: 1px solid var(--secondary-color);
  padding: 24px;
  margin: 16px;
  border-radius: 24px;
}

.Card-header {
  display: flex;
}

.Card-header img {
  width: 64px;
  height: 64px;
  border-radius: 12px;
  margin-right: 16px;
}
