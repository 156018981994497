.App {
  background-color: var(--background-color);
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App h1 {
  font-size: 64px;
  font-weight: 300;
}

.App h2 {
  font-size: 32px;
  font-weight: 200;
  letter-spacing: 4px;
  text-align: center;
}

.App h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.App h4 {
  font-size: 24px;
  font-weight: 500;
}

.App h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 8px 0;
}

.App p {
  font-size: 18px;
  color: var(--secondary-color);
}

.App-header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('images/headerBackground.svg');
  background-size: contain;
}


.App-header {
  background-color: var(--background-color);
  padding: 32px 48px;
  border-radius: 48px;
  margin: 72px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.App-header > * {
  padding: 24px;
}

.App-header img {
  width: 256px;
  height: 256px;
  border-radius: 50%;
}

.App-header-bio {
  min-width: 320px;
}

.App-header-text {
  margin: 0;
}

.App-header-icons {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.App-header-icons > a {
  color: var(--secondary-color);
  transition: color 100ms;
}

.App-header-icons > a:hover {
  color: var(--primary-color);
}

.App-header-icons svg {
  width: auto;
  height: 24px;
  padding-right: 24px;
  fill: currentColor;
}

.App-header-emailIcon {
  height: 20px;
  margin-top: 1px;
}

.App-section {
  padding: 24px;
}

#App-projects {
  background: var(--background-color-dark);
}

#App-passions img {
  border-radius: 0;
}


.App-section-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
}
